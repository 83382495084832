.panelNyan{
  position:absolute;
  left: 79.8%;
  background-color: hsl(220deg 12% 95%);
  height: 100% ;
  justify-content: center;
  z-index: 1 ;
  width: 80px;
  overflow: hidden;
 
}
.fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
  width: 96%;
}
.fjs-editor-container .fjs-properties-container {
  width: 17%;
}

.fjs-form-field {
  z-index: 200 !important;
}

@media (max-width: 2048px) and (max-height: 1280px) {
  .panelNyan{
    left: 78%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 94%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 17.5%;
  }
}
@media (max-width: 2048px) and (max-height: 1152px) {
  .panelNyan{
    left: 79.5%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 94%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 15%;
  }
}
@media (max-width: 2048px) and (max-height: 1080px) {
  .panelNyan{
    left: 80.5%;
  }
  
}

@media (max-width: 1920px) and (max-height: 1440px) {
  .panelNyan{
    left: 75.5%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 94%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 20.2%;
  }
}
@media (max-width: 1920px) and (max-height: 1200px) {
  .panelNyan{
    left: 78%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 95%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 17.2%;
  }
}
@media (max-width: 1920px) and (max-height: 1080px) {
  .panelNyan{
    left: 79.5%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 95%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 15.7%;
  }
}
@media (max-width: 1856px) and (max-height: 1392px) {
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 94%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 20%;
  }

}
@media (max-width: 1792px) and (max-height: 1344px) {
  .panelNyan{
    left: 74.6%;
  }
}
@media (max-width: 1680px) and (max-height: 1050px) {
  .panelNyan{
    left: 77.5%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 94%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 17%;
  }
}
@media (max-width: 1600px) and (max-height: 1200px) {
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 94%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 19.5%;
  }
}
@media (max-width: 1600px) and (max-height: 900px) {
  .panelNyan{
    left: 79%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 94%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 15.2%;
  }
}
@media (max-width: 1440px) and (max-height: 900px) {
  .panelNyan{
    left: 77.5%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 94%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 16%;
  }
}
@media (max-width: 1400px) and (max-height: 1050px) {
  .panelNyan{
    left: 74.2%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 91%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 18.9%;
  }
}
@media (max-width: 1366px) and (max-height: 768px) {
  .panelNyan{
    left: 78.5%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 92%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 14.2%;
  }
}
@media (max-width: 1280px) and (max-height: 1024px) {
  .panelNyan{
    left: 71%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 91%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 19%;
  }
}
@media (max-width: 1280px) and (max-height: 960px) {
  .panelNyan{
    left: 73.5%;
  }
}
@media (max-width: 1280px) and (max-height: 800px) {
  .panelNyan{
    left: 77%;
  }
  .fjs-editor-container .fjs-form-container, .fjs-editor-container .fjs-form {
    width: 92%;
  }
  .fjs-editor-container .fjs-properties-container {
    width: 15.2%;
  }
}
@media (max-width: 1280px) and (max-height: 768px) {
  .panelNyan{
    left: 77.5%;
  }
}

